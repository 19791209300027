import React, { useState, useEffect, useMemo, useCallback } from "react";
import axios from "axios";

const SpectatorEntries = () => {
  const [spectatorData, setSpectatorData] = useState([]);
  const [venueOptions, setVenueOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalUsers, setTotalUsers] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedVenues, setSelectedVenues] = useState([]);
  const LIMIT = 10;

  // Fetch venue options
  const fetchVenueOptions = useCallback(async () => {
    try {
      const response = await axios.get(
        "https://youth-festival-api.khelouk.in/venue-list"
      );
      // setVenueOptions( [
      //   { sportIds: ['opening'], name: 'Opening Ceremony',id:'opening' }
      //   ,...response.data.data.map((venue) => ({
      //     id: venue.id,
      //     name: venue.name,
      //     sportIds: venue.sports_facilities || [],
      //   }))
    
      // ]); // Corrected closing parenthesis
      // setVenueOptions([ 
      
      //   ,...venueOptions])
    
    
      setVenueOptions( [
        { sportIds: ['opening'], name: 'Opening Ceremony', id: 123411 },
        { sportIds: ['closing'], name: 'Closing Ceremony', id: 78943 },
        ...response.data.data.map((venue) => ({
          id: venue.id,
          name: venue.name,
          sportIds: venue.sports_facilities || [],
        }))
      ]);
      
    
    
    } catch (err) {
      console.error("Error fetching venues:", err);
    }
  }, []);
  // Fetch spectator data
  const fetchSpectatorData = useCallback(
    async (page) => {
      try {
        setIsLoading(true);

        let sportIdsArr = [];
        if (selectedVenues.length > 0) {
          let id = +selectedVenues[0];
          let { sportIds = [] } = venueOptions.find((venue) => venue.id == id);
          sportIdsArr = sportIds;
        }

        let obj = { limit: LIMIT, page: page };

        const queryParams = new URLSearchParams(obj);
        let url = `https://youth-festival-api.khelouk.in/spectator-user-list?${queryParams}`;

        if (sportIdsArr.length > 0) {
          for (let i = 0; i < sportIdsArr.length; i++) {
            url += `&sport_id=${sportIdsArr[i]}`;
          }
        }
        const response = await axios.get(url);

        const processedData = response.data.users.map((user, index) => ({
          serialNumber: (page - 1) * LIMIT + index + 1,
          id: user.id,
          name: user.full_name,
          age: user.age,
          email: user.email_id,
          mobile: user.mobile_number,
          city: user.city,
          sports: user.sport_id,
          createdAt: new Date(user.created_at).toLocaleDateString(),
        }));

        setSpectatorData(processedData);
        setTotalUsers(response.data.total);
        setError(null);
      } catch (err) {
        console.log(err)
        setError(err.message);
        setSpectatorData([]);
      } finally {
        setIsLoading(false);
      }
    },
    [selectedVenues, venueOptions]
  );

  // Initial data fetching
  useEffect(() => {
    fetchVenueOptions();
  }, [fetchVenueOptions]);

  useEffect(() => {
    fetchSpectatorData(currentPage);
  }, [currentPage, selectedVenues, fetchSpectatorData]);

  // Handle venue selection
  const handleVenueChange = (e) => {



    const value = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    setSelectedVenues(value);
    setCurrentPage(1);
  };

  const exportFile = async () => {
    try {
      let sportIdsArr = [];
      if (selectedVenues.length > 0) {
        let id = +selectedVenues[0];
        let { sportIds = [] } = venueOptions.find((venue) => venue.id == id);
        sportIdsArr = sportIds;
      }
      let url_link = `${process.env.REACT_APP_BACKENE_BASE_URL}/spectator-user-list/export-excel?`

      if (sportIdsArr.length > 0) {
        for (let i = 0; i < sportIdsArr.length; i++) {
          url_link += `&sport_id=${sportIdsArr[i]}`;
        }
      }

      const response = await axios.get(url_link, {
        responseType: "blob",
      });

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "Spectator_data.xlsx";
      link.click();

      window.URL.revokeObjectURL(url); // Clean up
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };
  // Pagination handlers
  const handleNextPage = () => {
    if (currentPage < Math.ceil(totalUsers / LIMIT)) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleGoToPage = (pageNumber) => {
    const validPage = Math.max(
      1,
      Math.min(pageNumber, Math.ceil(totalUsers / LIMIT))
    );
    setCurrentPage(validPage);
  };

  return (
    <div className="table-container">
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Spectator Entries</h4>
          <div
            style={{ height: "20px", width: "20px" }}
            onClick={() => {
              exportFile();
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
              <path d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 128-168 0c-13.3 0-24 10.7-24 24s10.7 24 24 24l168 0 0 112c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64L0 64zM384 336l0-48 110.1 0-39-39c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l80 80c9.4 9.4 9.4 24.6 0 33.9l-80 80c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l39-39L384 336zm0-208l-128 0L256 0 384 128z" />
            </svg>
          </div>
        </div>
        <div className="card-body">
          <div className="mb-3">
            <select
              className="form-control"
              onChange={handleVenueChange}
              value={selectedVenues}
            >
             <option value="" disabled >Select Venue</option>
              {venueOptions.map((venue) => (
                <option key={venue.id} value={venue.id}>
                  {venue.name}
                </option>
              ))}
            </select>
          </div>

          {isLoading ? (
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : error ? (
            <div className="alert alert-danger">{error}</div>
          ) : (
            <>
              <div className="table-responsive">
                <table className="table dataTable display">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Name</th>
                      <th>Age</th>
                      <th>Email</th>
                      <th>Mobile</th>
                      <th>City</th>
                      <th>Registered On</th>
                    </tr>
                  </thead>
                  <tbody>
                    {spectatorData.map((row) => (
                      <tr key={row.id}>
                        <td>{row.serialNumber}</td>
                        <td>{row.name}</td>
                        <td>{row.age}</td>
                        <td>{row.email}</td>
                        <td>{row.mobile}</td>
                        <td>{row.city}</td>
                        <td>{row.createdAt}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-between">
                <span>
                  Page{" "}
                  <strong>
                    {currentPage} of {Math.ceil(totalUsers / LIMIT)}
                  </strong>
                  {" | "}
                  Total Spectators: {totalUsers}
                </span>
                <span className="table-index">
                  Go to page:{" "}
                  <input
                    type="number"
                    className="form-control form-control-sm mx-2"
                    min={1}
                    max={Math.ceil(totalUsers / LIMIT)}
                    value={currentPage}
                    onChange={(e) => {
                      const pageNumber = e.target.value
                        ? Number(e.target.value)
                        : 1;
                      handleGoToPage(pageNumber);
                    }}
                    style={{ width: "60px", display: "inline-block" }}
                  />
                </span>
              </div>
              <div className="text-center">
                <div className="filter-pagination mt-3">
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => handleGoToPage(1)}
                    disabled={currentPage === 1}
                  >
                    {"<<"}
                  </button>
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={handleNextPage}
                    disabled={currentPage === Math.ceil(totalUsers / LIMIT)}
                  >
                    Next
                  </button>
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() =>
                      handleGoToPage(Math.ceil(totalUsers / LIMIT))
                    }
                    disabled={currentPage === Math.ceil(totalUsers / LIMIT)}
                  >
                    {">>"}
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SpectatorEntries;
